// @flow strict
import React from 'react';
import { Link } from 'gatsby';
import './style.scss';

const PaginationMenu = ({
  prevPagePath,
  nextPagePath,
  hasNextPage,
  hasPrevPage,
}) => {
  if (hasPrevPage || hasNextPage) return (
    <div className="pagination">
      <div className="pagination__prev">
        <Link
          rel="prev"
          to={hasPrevPage ? prevPagePath : '/'}
          href={hasPrevPage ? prevPagePath : '/'}
          className={`pagination__prev-link ${!hasPrevPage ? 'pagination__prev-link--disable' : ''}`}
        >
          Back
        </Link>
      </div>
      <div className="pagination__next">
        <Link
          rel="next"
          to={hasNextPage ? nextPagePath : '/'}
          href={hasNextPage ? nextPagePath : '/'}
          className={`pagination__next-link ${!hasNextPage ? 'pagination__next-link--disable' : ''}`}
        >
          Next
        </Link>
      </div>
    </div>
  );

  return <div />;
};

export default PaginationMenu;
